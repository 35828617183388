import React, {useRef} from "react";
import {CardHeader, CardContent, Card, Row, Col, PhotoBrowser, SkeletonBlock} from "framework7-react";

import {useAuth} from "contexts/auth";

import styles from "./home.module.css";

const MembershipCard = () => {
  const photoBrowser = useRef(null);
  const {session} = useAuth();

  return (
    <Card>
      <CardHeader><b>Membership Card</b></CardHeader>
      <CardContent>
        <Row>
          <Col className={styles.card}>
            {session.user && session.user.profile
              ? <>
                <img src={session.user.profile.card} alt={session.user.profile.full_name} onClick={() => photoBrowser.current.open()} />
                <PhotoBrowser toolbar={false} photos={[session.user.profile.card]} theme="dark" ref={photoBrowser} />
              </>
              : <SkeletonBlock style={{height: "150px"}} />
            }
          </Col>
        </Row>
      </CardContent>
    </Card>
  )
}

export default MembershipCard;
