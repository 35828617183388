import React, {useEffect, useState} from "react";

import { useAuth } from "contexts/auth";
import {AuthService} from "services";
import {Block, Card, CardContent, Col, f7, Page, Preloader, Row} from "framework7-react";
import styles from "../login/login.module.css";

const CallbackPage = ({f7router}) => {

  const {session, setSession} = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!session) {
      AuthService.callbackAuth().then((data) => {
        if (data) setSession(data);
        else setError(true);
      });
    }
  }, []);

  if (error) f7router.navigate("/login?error", {animate: false, props: {error: "Authentication Failure!"}});
  if (session) f7router.navigate("/", {animate: false});

  return (
    <Page name="register">
      <Block>
        <Row>
          <Col>
            <h1 className="text-align-center">
              <img className="margin aut" src="static/logo.png" alt="GPY Indonesia" height={70}/>
            </h1>
          </Col>
        </Row>
      </Block>
      <Block>
        <Row>
          <Col large={33} width={0}/>
          <Col large={33} width={100}>
            <Card className={styles.card}>
              <CardContent>
                <Preloader />
              </CardContent>
            </Card>
          </Col>
          <Col large={33} width={0}/>
        </Row>
      </Block>
    </Page>
  )
}

export default CallbackPage;
