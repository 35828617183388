import React from "react";
import {Page, Col, Row} from "framework7-react";

import Header from "./header";
import UpcomingEvent from "./upcoming-event";
import MembershipCard from "./membership-card";
import QrisDonation from "./qris-donation";

const HomePage = () => {
  return (
    <Page name="home">
      <Header/>
      <Row>
        <Col width={100} large={33} xlarge={25}>
          <MembershipCard/>
        </Col>
        <Col width={100} large={33} xlarge={50}>
          <UpcomingEvent/>
        </Col>
        <Col width={100} large={33} xlarge={25}>
          <QrisDonation/>
        </Col>
      </Row>
    </Page>
  )
}

export default HomePage;
