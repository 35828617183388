import React from "react";

import {useAuth} from "contexts/auth";

import styles from "./profile.module.css";

const Avatar = () => {
  const {session} = useAuth();

  return (
    <div className={styles.header}>
      <img className={styles.avatar} src={"https://ui-avatars.com/api/?name=" + session.user.profile.full_name} alt={session.user.profile.full_name} />
    </div>
  );
}

export default Avatar;
