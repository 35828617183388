import React, {useEffect} from "react";
import {Block, Card, CardContent, Col, f7, Page, Preloader, Row} from "framework7-react";

import { useAuth } from "contexts/auth";
import {SignupPopup} from "pages/register/signup-popup";

import styles from "./register.module.css";

const RegisterPage = () => {

  const {session} = useAuth();

  useEffect(() => {
    if (session.user.profile.guest) setTimeout(() => f7.popup.open("#signup-screen", true), 200);
    else {
      f7.popup.close("#signup-screen", true);
      window.location.reload();
    }
  }, [session]);

  return (
    <>
      <Page name="callback">
        <SignupPopup />
        <Block>
          <Row>
            <Col>
              <h1 className="text-align-center">
                <img className="margin aut" src="static/logo.png" alt="GPY Indonesia" height={70}/>
              </h1>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col large={33} width={0}/>
            <Col large={33} width={100}>
              <Card className={styles.card}>
                <CardContent>
                  <Preloader />
                </CardContent>
              </Card>
            </Col>
            <Col large={33} width={0}/>
          </Row>
        </Block>
      </Page>
    </>
  )
}

export default RegisterPage;
