import React, {useState, useEffect, useRef} from "react";
import {Block, Button, f7, Navbar, Page, PhotoBrowser} from "framework7-react";
import PropTypes from "prop-types";

import {DataService} from "services";

import styles from "./event.module.css"

const EventDetailPage = ({ slug }) => {
  const eventId = slug.split("-", 2)[0];

  const photoBrowser = useRef(null);
  const [item, setItem] = useState(null);

  const loaded = () => item != null;

  const loadItem = () => {
    f7.preloader.show();
    DataService.get("/v1/events/" + eventId).then((response) => {
      setItem(response);
      f7.preloader.hide();
    });
  }

  const conditionalContent = () => {
    if (!loaded()) return <></>
    return <>
      <PhotoBrowser toolbar={false} photos={[item.featured_image]} theme="dark" ref={photoBrowser} />
      <div className={styles.backdrop} onClick={() => photoBrowser.current.open()}>
        <div className={styles.background} style={{background: "url(" + item.featured_image + ")"}}/>
        <img src={item.featured_image} alt={item.name}/>
      </div>
      <div className={styles.container}>
        <Block className={styles.headingBlock}>
          <h1 className={styles.center}>{item.name}</h1>
        </Block>
        <div>
          <Block>
            <div dangerouslySetInnerHTML={{__html: item.description}}/>
          </Block>
          <Block>
            <Button fill large href={item.registration_link} target="_blank" external>
              Join this event
            </Button>
          </Block>
        </div>
      </div>
    </>
  }

  useEffect(() => loadItem(), [])

  return (
    <Page name="event-detail">
      <Navbar backLink="Back" transparent sliding={false} innerClassName={styles.navbar}/>
      {conditionalContent()}
    </Page>
  );
};

EventDetailPage.defaultProps = {
  slug: "",
};

EventDetailPage.propTypes = {
  slug: PropTypes.string,
};

export default EventDetailPage;
