import React from "react";
import {Page, Navbar, f7} from "framework7-react";

import ProfileForm from "components/profile-form";

import {useAuth} from "contexts/auth";
import {AuthService, DataService} from "services";

import styles from "./profile.module.css";

const ProfileEditPage = ({f7router}) => {

  const {session, setSession} = useAuth();

  const showError = (e) => {
    f7.dialog.close();
    f7.dialog.alert("Failed to save your data!", "Opps");
    console.error(e);
  }

  const save = (payload) => {
    f7.dialog.preloader();
    DataService.put('/v1/user/profile/', payload).then((response) => {
      if (response.error) showError(response.error);
      else {
        f7.dialog.close();
        f7router.back("/profile");
        AuthService.callbackAuth(session.token).then((data) => {
          if (data) setSession(data);
        });
      }
    }).catch((e) => showError(e));
  }

  return (
    <Page name="profile">
      <Navbar backLink="Back" />
      <div className={styles.container}>
        <div className={styles.header}>
          <h2>Edit Profile</h2>
        </div>
        <div className={styles.form}>
          <ProfileForm disabledComponents={['email', 'first_name', 'last_name']} afterSubmit={(payload) => save(payload)}/>
        </div>
        <br/>
      </div>
    </Page>
  );
}

export default ProfileEditPage;
