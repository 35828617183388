import React from "react";
import { Block, Col, Link, List, ListItem, Popover, Row, f7 } from "framework7-react";

import { useAuth } from "contexts/auth";

import styles from "./home.module.css"

const Header = () => {

  const { session, setSession } = useAuth();

  const logout = () => {
    f7.popover.close(".popover-menu", false);
    setSession(false);
  }

  return (
    <Block className={styles.header}>
      <Row>
        <Col width="75">
          <h1>
            <img className="margin-top margin-left" src="static/logo.png" alt="GPY Indonesia" height={50}/>
          </h1>
        </Col>
        {session ?
          <Col width="25" className={styles.colUser}>
            <Link popoverOpen=".popover-menu">
              <img className={styles.avatar} src={"https://ui-avatars.com/api/?name=" + session.user.profile.full_name} alt={session.user.profile.full_name} />
            </Link>
          </Col>
        : ''}
        <Popover className="popover-menu">
          <List>
            <ListItem link="/profile" popoverClose title="Profile" />
            <ListItem onClick={() => logout()} popoverClose title="Logout" />
          </List>
        </Popover>
      </Row>
    </Block>
  )
}

export default Header;
