import React, {useState, useEffect} from "react";
import {List, ListInput, Button, ListItem, f7, Block} from "framework7-react";

import {useAuth} from "contexts/auth";
import {DataService} from "services";
import PropTypes from "prop-types";

export const ProfileForm = ({afterSubmit, submitText, disabledComponents}) => {

  const {session} = useAuth();

  const defaultParam = {
    email: '',
    first_name: '',
    last_name: '',
    gender: '',
    date_of_birth: '',
    religion: '',
    country_iso_code: '',
    mobile_phone: '',
    education_subject: '',
    job: '',
    domicile_regency_code: '',
    experience_in_gpf: ''
  };

  const [params, setParams] = useState(defaultParam);
  const [countryData, setCountryData] = useState([]);
  const [provinceData, setProvinceData] = useState([]);
  const [regencyData, setRegencyData] = useState([]);
  const [selectedCountryIndex, setSelectedCountryIndex] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvinceIndex, setSelectedProvinceIndex] = useState('');
  const [selectedRegencyIndex, setSelectedRegencyIndex] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (a, b) => {
    const {id, value} = typeof b === 'undefined' ? a.target : {id: a, value: b};
    setParams(prevState => ({...prevState, [id]: value}));
    setErrors(prevState => ({...prevState, [id]: null}));
  };

  const handleCountryChange = (e) => {
    const {id, value} = e.target;
    const selectedCountry = countryData[value];
    setSelectedCountryIndex(value);
    setSelectedCountry(selectedCountry);
  };

  const handleProvinceChange = (e) => {
    const {value} = e.target;
    setSelectedProvinceIndex(value);
    setErrors(prevState => ({...prevState, domicile_province_code: null}));
  };

  const handleRegencyChange = (e) => {
    const {value} = e.target;
    setSelectedRegencyIndex(value);
    setParams(prevState => ({...prevState, domicile_regency_code: regencyData[value].id}));
    setErrors(prevState => ({...prevState, domicile_regency_code: null}));
  };

  const handlePhoneNumberChange = (e) => {
    const {id, value} = e.target;
    if (selectedCountry === null) return;
    const prefix = selectedCountry.country_code + " ";
    if (value.length < prefix.length) setParams(prevState => ({...prevState, [id]: prefix}));
    else {
      if (value.substr(0, prefix.length) === prefix) {
        let input = value.replace(prefix, "");
        if (input.length > 12) return;
        if (input.length > 0 && !/^\d+$/.test(input)) return;
        setParams(prevState => ({...prevState, [id]: value}));
      }
    }
    setErrors(prevState => ({...prevState, [id]: null}));
  };

  const setError = (key, value) => {
    setErrors(prevState => ({...prevState, [key]: value}));
  }

  const toIsoString = (date) => {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? '+' : '-',
      pad = function(num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? '0' : '') + norm;
      };
    return date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate()) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);
  }

  const handleSubmit = () => {
    setErrors({});

    let isError = false;
    if (params.email.length === 0) { isError = true; setError("email", "This field is required") }
    if (params.first_name.length === 0) { isError = true; setError("first_name", "This field is required") }
    if (params.last_name.length === 0) { isError = true; setError("last_name", "This field is required") }
    if (params.gender.length === 0) { isError = true; setError("gender", "This field is required") }
    if (params.date_of_birth.length === 0) { isError = true; setError("date_of_birth", "This field is required") }
    if (params.religion.length === 0) { isError = true; setError("religion", "This field is required") }
    if (params.country_iso_code.length === 0) { isError = true; setError("country_iso_code", "This field is required") }
    if (params.country_iso_code === "id") {
      if (selectedProvinceIndex === '') { isError = true; setError("domicile_province_code", "This field is required") }
      if (params.domicile_regency_code === null || params.domicile_regency_code.length === 0) { isError = true; setError("domicile_regency_code", "This field is required") }
    }
    if (params.mobile_phone.length === 0 || params.mobile_phone.length === selectedCountry.country_code.length + 1) { isError = true; setError("mobile_phone", "This field is required") }
    if (params.education_subject.length === 0) { isError = true; setError("education_subject", "This field is required") }
    if (params.job.length === 0) { isError = true; setError("job", "This field is required") }

    if (isError) return;

    let payload = {...params};
    delete payload.email;
    payload.date_of_birth = toIsoString(params.date_of_birth[0]).split('T')[0];
    payload.mobile_phone = params.mobile_phone.replace(" ", "");

    afterSubmit(payload);
  };

  const loadCountries = () => {
    setLoading(true);
    DataService.get("/v1/countries?limit=-1").then((response) => {
      if (!response.error) setCountryData(response.data);
    }).finally(() => setLoading(false));
  }

  const loadProvinces = () => {
    setLoading(true);
    DataService.get("/v1/provinces?limit=-1").then((response) => {
      if (!response.error) {
        let data = response.data;
        data.unshift({name: 'Select province'});
        setProvinceData(data);
      }
    }).finally(() => setLoading(false));
  }

  const loadRegencies = () => {
    setLoading(true);
    DataService.get("/v1/provinces/" + provinceData[selectedProvinceIndex].id + "/regencies?limit=-1").then((response) => {
      if (!response.error) {
        let data = response.data;
        data.unshift({name: 'Select regency'});
        setRegencyData(data);
      }
    }).finally(() => setLoading(false));
  }

  const isDisabled = (key) => {
    return disabledComponents.indexOf(key) !== -1;
  }

  useEffect(() => {
    if (selectedCountry && selectedCountry.id !== params.country_iso_code) {
      setSelectedProvinceIndex('');
      setSelectedRegencyIndex('');
      setRegencyData([]);
      setParams(prevState => ({...prevState,
        domicile_regency_code: null,
        mobile_phone: selectedCountry.country_code + " ",
        country_iso_code: selectedCountry.id,
      }));
      setErrors(prevState => ({...prevState,
        domicile_province_code: null,
        domicile_regency_code: null,
        mobile_phone: null,
        country_iso_code: null,
      }));
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedProvinceIndex === '') return;
    loadRegencies();
  }, [selectedProvinceIndex]);

  useEffect(() => {
    if (countryData.length === 0) return;
    const indonesiaIndex = 101;
    const selectedCountryIndex = params.country_iso_code === ""
      ? indonesiaIndex
      : countryData.findIndex((item) => item.id === params.country_iso_code);
    if (selectedCountryIndex !== -1) {
      const selectedCountry = countryData[selectedCountryIndex];
      setSelectedCountryIndex(selectedCountryIndex);
      setSelectedCountry(selectedCountry);
    }
    else {
      setSelectedCountryIndex('');
      setSelectedCountry(null);
    }
  }, [countryData]);

  useEffect(() => {
    if (provinceData.length === 0) return;
    if (params.domicile_regency_code === null || params.domicile_regency_code === "") return;
    const provinceIndex = provinceData.findIndex((item) => item.id === params.domicile_regency_code.substr(0, 2));
    if (provinceIndex !== -1) setSelectedProvinceIndex(provinceIndex);
    else setSelectedProvinceIndex('');
  }, [provinceData]);

  useEffect(() => {
    if (regencyData.length === 0) return;
    if (params.domicile_regency_code === null || params.domicile_regency_code === "") return;
    const regencyIndex = regencyData.findIndex((item) => item.id === params.domicile_regency_code);
    if (regencyIndex !== -1) setSelectedRegencyIndex(regencyIndex);
    else setSelectedRegencyIndex('');
  }, [regencyData]);

  useEffect(() => {
    if (countryData.length > 0 || provinceData.length > 0) return;
    if (session) {
      setParams(prevState => ({...prevState,
        email: session.user.profile.email,
        first_name: session.user.profile.first_name || "",
        last_name: session.user.profile.last_name || "",
        gender: session.user.profile.gender === "-" ? "" : session.user.profile.gender.substr(0, 1),
        date_of_birth: session.user.profile.date_of_birth === null ? '' : [new Date(session.user.profile.date_of_birth)],
        religion: session.user.profile.religion || "",
        country_iso_code: session.user.profile.country_iso_code || "",
        mobile_phone: session.user.profile.mobile_phone?.replace(session.user.profile.country.calling_code, session.user.profile.country.calling_code + " ") || "",
        education_subject: session.user.profile.education_subject || "",
        job: session.user.profile.job || "",
        domicile_regency_code: session.user.profile.domicile_regency_code || "",
        experience_in_gpf: session.user.profile.experience_in_gpf || "",
      }));

      loadCountries();
      loadProvinces();
    }
  }, [session]);

  useEffect(() => {
    if (loading) f7.preloader.show();
    else f7.preloader.hide();
  }, [loading])

  return (
    <>
      <List>
        <ListInput
          label="Email"
          type="text"
          value={params.email}
          disabled={isDisabled('email')}
        />
        <ListInput
          inputId="first_name"
          label="First Name*"
          placeholder="First Name"
          type="text"
          value={params.first_name}
          disabled={isDisabled('first_name')}
          onChange={handleChange}
          errorMessage={errors.first_name}
          errorMessageForce={errors.first_name != null}
        />
        <ListInput
          inputId="last_name"
          label="Last Name*"
          type="text"
          placeholder="Last Name"
          value={params.last_name}
          disabled={isDisabled('last_name')}
          onChange={handleChange}
          errorMessage={errors.last_name}
          errorMessageForce={errors.last_name != null}
        />
        <ListInput
          inputId="gender"
          label="Gender*"
          type="select"
          value={params.gender}
          disabled={isDisabled('gender')}
          onChange={handleChange}
          errorMessage={errors.gender}
          errorMessageForce={errors.gender != null}
        >
          <option disabled value="">Please choose...</option>
          <option value="M">Male</option>
          <option value="F">Female</option>
        </ListInput>
        <ListInput
          label="Birth Date*"
          type="datepicker"
          placeholder="Date of birth"
          value={params.date_of_birth}
          disabled={isDisabled('date_of_birth')}
          calendarParams={{dateFormat: "dd MM yyyy", closeOnSelect: true}}
          onCalendarChange={(value) => handleChange("date_of_birth", value)}
          errorMessage={errors.date_of_birth}
          errorMessageForce={errors.date_of_birth != null}
        />
        <ListInput
          inputId="religion"
          label="Religion*"
          placeholder="Religion"
          type="text"
          value={params.religion}
          disabled={isDisabled('religion')}
          onChange={handleChange}
          errorMessage={errors.religion}
          errorMessageForce={errors.religion != null}
        />
        <ListItem header="Domicile*"/>
        <ListItem
          title="Country"
          smartSelect
          smartSelectParams={{
            openIn: 'popup',
            searchbar: true,
            closeOnSelect: true,
            searchbarPlaceholder: 'Search country by name',
            scrollToSelectedItem: true,
            pageTitle: "Country",
            setValueText: false,
          }}
          after={selectedCountry != null ? `${selectedCountry.flag} ${selectedCountry.id.toUpperCase()}` : ''}
          footer={errors.country_iso_code}
          className={errors.country_iso_code != null ? 'error' : ''}
        >
          <select id="country_iso_code" value={selectedCountryIndex} onChange={handleCountryChange}>
            {countryData.map((item, index) => <option key={index} value={index}>{item.flag} {item.name}</option>)}
          </select>
        </ListItem>
        {selectedCountry?.id === 'id' ?
          <ListItem
            title="Province"
            smartSelect
            smartSelectParams={{
              openIn: 'sheet',
              closeOnSelect: true,
              pageTitle: "Province",
              setValueText: false,
            }}
            after={selectedProvinceIndex !== '' ? provinceData[selectedProvinceIndex].name : ''}
            footer={errors.domicile_province_code}
            className={errors.domicile_province_code != null ? 'error' : ''}
          >
            <select id="province" onChange={handleProvinceChange}>
              {provinceData.map((item, index) => <option key={index} value={index}>{item.name}</option>)}
            </select>
          </ListItem>
          : ''}
        {selectedProvinceIndex !== '' && regencyData.length > 0 ?
          <ListItem
            title="Regency"
            smartSelect
            smartSelectParams={{
              openIn: 'sheet',
              closeOnSelect: true,
              pageTitle: "Regency",
              setValueText: false,
            }}
            after={selectedRegencyIndex !== '' ? regencyData[selectedRegencyIndex].name : ''}
            footer={errors.domicile_regency_code}
            className={errors.domicile_regency_code != null ? 'error' : ''}
          >
            <select id="regency" onChange={handleRegencyChange}>
              {regencyData.map((item, index) => <option key={index} value={index}>{item.name}</option>)}
            </select>
          </ListItem>
          : ''}
        <ListInput
          inputId="mobile_phone"
          label="Phone Number*"
          placeholder={"Select country first"}
          type="tel"
          value={params.mobile_phone}
          disabled={isDisabled('mobile_phone')}
          onChange={handlePhoneNumberChange}
          errorMessage={errors.mobile_phone}
          errorMessageForce={errors.mobile_phone != null}
        />
        <ListInput
          inputId="education_subject"
          label="Education Subject*"
          placeholder="Education Subject"
          type="text"
          value={params.education_subject}
          disabled={isDisabled('education_subject')}
          onChange={handleChange}
          errorMessage={errors.education_subject}
          errorMessageForce={errors.education_subject != null}
        />
        <ListInput
          inputId="job"
          label="Job*"
          placeholder="Job"
          type="text"
          value={params.job}
          disabled={isDisabled('job')}
          onChange={handleChange}
          errorMessage={errors.job}
          errorMessageForce={errors.job != null}
        />
        <ListInput
          inputId="experience_in_gpf"
          label="Experience in GPF"
          placeholder="Write down your experiences in GPF"
          type="textarea"
          value={params.experience_in_gpf}
          disabled={isDisabled('experience_in_gpf')}
          onChange={handleChange}
          errorMessage={errors.experience_in_gpf}
          errorMessageForce={errors.experience_in_gpf != null}
        />
      </List>
      <Button fill onClick={() => handleSubmit()}>
        {submitText}
      </Button>
    </>
  );

};

ProfileForm.defaultProps = {
  afterSubmit: (payload) => console.log(payload),
  submitText: 'Save',
  disabledComponents: ['email'],
};

ProfileForm.propTypes = {
  afterSubmit: PropTypes.func,
  submitText: PropTypes.string,
  disabledComponents: PropTypes.array,
};

export default ProfileForm;
