import React from "react";
import {Page, Navbar, ListInput, List} from "framework7-react";
import {useAuth} from "contexts/auth";

import Avatar from "./avatar";
import ToolbarHeader from "./toolbar-header";
import styles from "./profile.module.css";

const ProfilePage = () => {
  const {session} = useAuth();
  const profile = session.user.profile;

  return (
    <Page name="profile">
      <Navbar backLink="Back" />
      <div className={styles.container}>
        <Avatar />
        <ToolbarHeader />
        <List className="no-padding-left">
          <ListInput
            label="Email"
            type="text"
            value={profile.email || "-"}
            readonly
          />
          <ListInput
            label="First Name"
            type="text"
            value={profile.first_name || "-"}
            readonly
          />
          <ListInput
            label="Last Name"
            type="text"
            value={profile.last_name || "-"}
            readonly
          />
          <ListInput
            label="Gender"
            type="text"
            value={profile.gender || "-"}
            readonly
          />
          <ListInput
            label="Birth Date"
            type="text"
            value={profile.date_of_birth || "-"}
            readonly
          />
          <ListInput
            label="Religion"
            type="text"
            value={profile.religion || "-"}
            readonly
          />
          <ListInput
            label="Domicile"
            type="text"
            value={profile.country.flag + " " + (!profile.domicile ? profile.country.name : profile.domicile)}
            readonly
          />
          <ListInput
            label="Phone Number"
            type="text"
            value={profile.mobile_phone || "-"}
            readonly
          />
          <ListInput
            label="Education Subject"
            type="text"
            value={profile.education_subject || "-"}
            readonly
          />
          <ListInput
            label="Job"
            type="text"
            value={profile.job || "-"}
            readonly
          />
          <ListInput
            label="Experience in GPF"
            type="textarea"
            value={profile.experience_in_gpf || "-"}
            readonly
          />
        </List>
      </div>
    </Page>
  );
}

export default ProfilePage;
