import React, {useEffect, useState} from "react";
import {List, ListItem, CardHeader, CardContent, Card} from "framework7-react";
import slugify from 'react-slugify';

import {DataService} from "services";

const UpcomingEvent = () => {
  const dummyTitle = "Lorem ipsum dolor sit amet";
  const dummyText  = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

  const [items, setItems] = useState(null);

  const loaded = () => items != null;

  const loadItems = () => {
    const limit = 5;
    DataService.get("/v1/events?active_only=true&limit=" + limit).then((response) => {
      setItems(response.data);
    });
  }

  const conditionalContent = () => {
    if (!loaded()) return <ListItem title={dummyTitle} text={dummyText} className={"skeleton-text skeleton-effect-wave"}/>
    if (items.length === 0) return <ListItem text="No upcoming event right now"/>
    return items.map((item, index) =>
      <ListItem
        key={index}
        link={"/event/" + item.id + "-" + slugify(item.name)}
        title={item.name}
        text={!item.description ? '' : item.description.replace('<br>', ' ').replace(/(<([^>]+)>)/ig, '')}
        chevronCenter
      />
    )
  };

  useEffect(() => loadItems(), [])

  return (
    <Card>
      <CardHeader><b>Upcoming Events</b></CardHeader>
      <CardContent>
        <List mediaList>
          {conditionalContent()}
        </List>
      </CardContent>
    </Card>
  )
}

export default UpcomingEvent;
