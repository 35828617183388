import React from "react";
import {Page, LoginScreenTitle, BlockHeader, Block, f7, Popup, Link} from "framework7-react";

import ProfileForm from "components/profile-form";
import {useAuth} from "contexts/auth";
import {AuthService, DataService} from "services";

export const SignupPopup = () => {

  const {session, setSession} = useAuth();

  const showError = (e) => {
    f7.dialog.close();
    f7.dialog.alert("Failed to save your data!", "Opps");
    console.error(e);
  }

  const signup = (payload) => {
    f7.dialog.confirm("Your name will be printed in membership card and can't be changed later", "Confirmation", () => {
      f7.dialog.preloader();
      DataService.put('/v1/user/profile/', payload).then((response) => {
        if (response.error) showError(response.error);
        else {
          f7.dialog.close();
          f7.popup.close("#signup-screen", true)
          AuthService.callbackAuth(session.token).then((data) => {
            if (data) setSession(data);
          });
        }
      }).catch((e) => showError(e));
    });
  }

  return (
    <Popup id="signup-screen" className="inset-popup" closeByBackdropClick={false}>
      <Page loginScreen>
        <div slot="fixed" className="swipe-handler" />
        <LoginScreenTitle>Registration Form</LoginScreenTitle>
        <BlockHeader>Hi, please fill out the form below to register ✍️.</BlockHeader>
        <Block>
          <ProfileForm submitText="Sign Up" afterSubmit={(payload) => signup(payload)}/>
          <p>By signing up, you agree to our <Link href="https://sites.google.com/globalpeace.org/gpyindonesia/keanggotaan" target="_blank" external>Terms of service</Link>.</p>
        </Block>
      </Page>
    </Popup>
  );
}

export default SignupPopup;
