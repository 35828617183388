import React, {useEffect, useState} from "react";
import {App, View} from "framework7-react";
import {AuthService} from "services";
import {AuthContext} from 'contexts';
import {AUTH_KEY} from "constants/Environment";
import routes from "js/routes";

export const AppMain = () => {
  const f7params = {
    name: "GPY Indonesia Membership",
    theme: "ios",
    routes: routes,
  };

  const [session, setSession] = useState(null);

  useEffect(() => {
    const sessionFromStorage = localStorage.getItem(AUTH_KEY);
    if (sessionFromStorage != null && sessionFromStorage !== 'undefined') {
      let existingSession = JSON.parse(sessionFromStorage);
      setSession(existingSession);

      AuthService.fetchIdentity(existingSession.token).then((response) => {
        if (typeof response.profile === 'undefined') setSession(false);
        else {
          existingSession.user = response;
          existingSession.last_updated = new Date();
          setSession(existingSession);
          localStorage.setItem(AUTH_KEY, JSON.stringify(existingSession));
        }
      }).catch(() => setSession(false));
    }
  }, []);

  useEffect(() => {
    if (session == null) return;
    if (session === false) {
      localStorage.removeItem(AUTH_KEY);
      window.location.reload();
    }
    else {
      localStorage.setItem(AUTH_KEY, JSON.stringify(session));
    }
  }, [session]);

  return (
    <App params={f7params}>
      <AuthContext.Provider value={{ session, setSession }}>
        {/* Your main view, should have "view-main" class */}
        <View
          main
          className="safe-areas"
          url="/"
          pushState={true}
          pushStateSeparator={""}
        />
      </AuthContext.Provider>
    </App>
  );
};

export default AppMain;
