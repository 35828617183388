import HomePage from "pages/home";
import ProfilePage from "pages/profile";
import ProfileEditPage from "pages/profile/edit";
import EventDetailPage from "pages/event";
import NotFoundPage from "pages/404";
import LoginPage from "pages/login";
import RegisterPage from "pages/register";
import CallbackPage from "pages/callback";

import {AUTH_KEY} from "../constants/Environment";

const getSavedSession = () => {
  const sessionFromStorage = localStorage.getItem(AUTH_KEY);
  let existingSession = JSON.parse(sessionFromStorage);
  if (existingSession === null || existingSession === 'undefined') {
    localStorage.removeItem(AUTH_KEY);
    return null;
  }
  let isExpired = new Date(existingSession.expired_at) < new Date();
  if (isExpired) {
    localStorage.removeItem(AUTH_KEY);
    return null;
  }
  return existingSession;
}

const isAuthenticated = () => {
  return getSavedSession() != null;
}

function authenticatedOnly(to, from, resolve, reject) {
  const router = this;
  if(!isAuthenticated()) {
    reject();
    router.navigate('/login', {animate: false});
  } else {
    resolve();
  }
}

function registeredOnly(to, from, resolve, reject) {
  const router = this;
  if(!isAuthenticated()) {
    reject();
    router.navigate('/login', {animate: false});
  } else {
    if (getSavedSession().user.profile.guest) {
      reject();
      router.navigate('/register', {animate: false});
    }
    else resolve();
  }
}

function guestOnly(to, from, resolve, reject) {
  const router = this;
  if(!isAuthenticated()) {
    reject();
    router.navigate('/login', {animate: false});
  } else {
    if (!getSavedSession().user.profile.guest) {
      reject();
      router.navigate('/', {animate: false});
    }
    else resolve();
  }
}

function unauthenticatedOnly(to, from, resolve, reject) {
  const router = this;
  if(isAuthenticated()) {
    reject();
    router.navigate('/', {animate: false});
  } else {
    resolve();
  }
}

const routes = [
  {
    path: "/",
    component: HomePage,
    beforeEnter: registeredOnly,
  },
  {
    path: "/register",
    component: RegisterPage,
    beforeEnter: guestOnly,
  },
  {
    path: "/login",
    component: LoginPage,
    beforeEnter: unauthenticatedOnly,
  },
  {
    path: "/callback",
    component: CallbackPage,
    beforeEnter: unauthenticatedOnly,
  },
  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/profile/edit",
    component: ProfileEditPage,
  },
  {
    path: "/event/:slug",
    component: EventDetailPage,
  },
  {
    path: "(.*)",
    component: NotFoundPage,
  },
];

export default routes;
