import React from "react";
import {Block, Button, Card, CardContent, CardFooter, Col, Page, Row} from "framework7-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faYahoo, faGoogle} from "@fortawesome/free-brands-svg-icons";

import {AuthService} from "services";

import styles from "./login.module.css";

const LoginPage = ({error}) => {
  return (
    <Page name="login">
      <Block>
        <Row>
          <Col>
            <h1 className="text-align-center">
              <img className="margin aut" src="static/logo.png" alt="GPY Indonesia" height={70}/>
            </h1>
          </Col>
        </Row>
      </Block>
      <Block>
        <Row>
          <Col large={33} width={0}/>
          <Col large={33} width={100}>
            <Card className={styles.card}>
              <CardContent>
                <h3>GPY Membership</h3>
                <p>Please continue with your google or yahoo account</p>
                <Button color="red" fill large onClick={() => AuthService.signInWithGoogle()}>
                  <FontAwesomeIcon icon={faGoogle} className="margin-right"/>
                  Login with Google
                </Button>
                <Button className="margin-top" color="purple" fill large onClick={() => AuthService.signInWithYahoo()}>
                  <FontAwesomeIcon icon={faYahoo} className="margin-right"/>
                  Login with Yahoo
                </Button>
              </CardContent>
              {error &&
                <CardFooter>
                  <p className="text-color-red">{error}</p>
                </CardFooter>
              }
            </Card>
          </Col>
          <Col large={33} width={0}/>
        </Row>
      </Block>
    </Page>
  )
}

export default LoginPage;
